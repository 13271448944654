














































































































import Component from "vue-class-component";
import { Vue, Watch } from "vue-property-decorator";
import { ParsingFullTablePerDate } from "@/healthcheck/model/ParsingFullTablePerDate";
import { ParsingMetric } from "@/healthcheck/model/ParsingFullResponse";

@Component
export default class ParsingView extends Vue {
  readonly items = [{ tab: "per date" }, { tab: "per metric" }];
  readonly timePeriod = 14;
  readonly parsingPerDateHeaders = [
    {
      text: this.$lang("parsingTablePerDate.headers.metric"),
      align: "start",
      value: "metric",
    },
    {
      text: this.$lang("parsingTablePerDate.headers.adjustValue"),
      value: "adjustValue",
    },
    {
      text: this.$lang("parsingTablePerDate.headers.bigQueryValue"),
      value: "bigQueryValue",
    },
    {
      text: this.$lang("parsingTablePerDate.headers.deltaValue"),
      value: "deltaValue",
    },
    {
      text: this.$lang("parsingTablePerDate.headers.deltaPercent"),
      value: "deltaPercent",
    },
  ];
  readonly parsingPerMetricHeaders = [
    {
      text: this.$lang("parsingTablePerMetric.headers.date"),
      align: "start",
      value: "date",
    },
    {
      text: this.$lang("parsingTablePerMetric.headers.adjustValue"),
      value: "adjustValue",
    },
    {
      text: this.$lang("parsingTablePerMetric.headers.bigQueryValue"),
      value: "bigQueryValue",
    },
    {
      text: this.$lang("parsingTablePerMetric.headers.deltaValue"),
      value: "deltaValue",
    },
    {
      text: this.$lang("parsingTablePerMetric.headers.deltaPercent"),
      value: "deltaPercent",
    },
  ];

  currentTab = 0;
  tab = null;

  get isParsingLoading(): boolean {
    return this.$store.state.healthCheckStore.isParsingLoading;
  }

  get isParsingTableExist(): boolean {
    return this.$store.state.healthCheckStore.metrics.length !== 0;
  }

  get startDate(): string {
    return this.$store.state.healthCheckStore.startDate;
  }
  get endDate(): string {
    return this.$store.state.healthCheckStore.endDate;
  }

  get parsingPerDateTables(): Array<ParsingFullTablePerDate> {
    return this.$store.state.healthCheckStore.parsingPerDateTables;
  }

  get parsingPerMetricTables(): Array<ParsingMetric> {
    return this.$store.state.healthCheckStore.parsingPerMetricTables;
  }

  get parsingMetrics(): string {
    return this.$store.state.healthCheckStore.metrics
      .map((it: string) => it.toLowerCase())
      .join(", ");
  }

  get appId(): string {
    return this.$route.params.appId;
  }

  get applicationName(): string {
    return (
      this.$store.getters.applicationsById.get(this.appId)?.name ?? this.appId
    );
  }

  getColor(percent: string): string {
    const value = parseInt(percent.replace("%", ""));
    let color = 100;

    if (value >= 15) {
      color = 0;
    } else if (value >= 10) {
      color = 16;
    } else if (value >= 5) {
      color = 51;
    }

    return `hsl(${color}, 100%, 40%)`;
  }

  @Watch("appId", { immediate: true })
  watchApplicationId() {
    this.$store.dispatch("getParsingFullData", {
      applicationId: this.appId,
    });
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("parsingTable.title")
    );
  }
}
